html[data-theme="light"] {
  --white: #fff;
  --black: #000;
}
html[data-theme="dark"] {
  $form-select-indicator-color: white;
  --black: #fff;
  --white: #000;
  --box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.3);
  & .sidemenubox li a:hover,
  .sidemenubox li button:hover,
  .sidemenubox li a.active,
  .sidemenubox li button.active {
    color: #fff;
    background: $primary;
  }
  .rdt_TableHead,
  .rdt_TableRow,
  .rdt_Pagination {
    color: #fff;
  }
  .rdt_Pagination button{
    fill: #fff;
  }
  .rdt_TableHeadRow,
  .rdt_Pagination {
    background-color: #333;
  }
  .rdt_Table,
  .rdt_TableRow {
    background-color: transparent;
  }
  & .sidemenuheader,
  .form-control,
  .form-select,
  .adminfooter,
  .table-bordered > :not(caption) > *,
  .table > :not(:first-child) {
    border-color: #323232;
  }
  & .pagesec,
  .modal-content,
  .counterbox {
    background-color: #000;
  }
  & .sidecontent,
  .submenu,
  .form-control,
  .form-select {
    background-color: #333;
  }
  & .text-dark,
  .table,
  .btn:hover,
  .form-control,
  .form-select,
  .counterbox .counterboxdata p.value {
    // color: #fff !important;
    color: #a89a9a !important;
  }

  & .form-select {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  }
  & .damagerow_div {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: #464646;
  }
  & .bg-light,
  .page-link {
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-color: #464646 !important;
  }
  & .page-link {
    color: #858585;
  }
  & .active > .page-link {
    color: $primary;
  }
  & .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
      center/1em auto no-repeat;
  }
}
